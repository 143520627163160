.about {
  position: relative;
  width: 100%;
  min-height: 70vh; /* Ajusta la altura según tus necesidades */
  background: url('/src/assets/showcase-bg.jpeg') no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  background-attachment: fixed;
}

.about-video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  pointer-events: none;
}

.about p {
  padding: 50px 20px;
  color: white;
  z-index: 99!important;
}

.about p a {
  background: #235d87;
  padding: 5px 10px;
}

.overlay-about {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 1;
}

.about-text {
  position: relative;
  z-index: 2;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}