.skills {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9; /* Cambia el color de fondo según tu preferencia */
  }
  
  .skills-title {
    margin-bottom: 20px;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
  }
  
  .skill-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
  
  .skill-name {
    font-size: 1rem;
    font-weight: bold;
  }